<template>
  <teleport v-if="open" to="body">
    <transition
      enter-active-class="duration-300 ease-out"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-to-class="opacity-0"
    >
      <div
        v-show="open"
        class="fixed inset-0 overflow-y-auto"
        :class="[open ? 'z-50' : '-z-10']"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
        >
          <div
            v-show="open"
            class="fixed inset-0 transition-opacity"
            @click="(emit('close-modal'), emit('update:open', false))"
          >
            <div class="absolute inset-0 bg-black opacity-75" />
          </div>
          <span class="hidden sm:h-screen sm:align-middle">&nbsp;</span>
          <transition
            enter-active-class="duration-300 ease-out"
            enter-to-class="translate-y-0 opacity-100 sm:scale-100"
            leave-active-class="duration-200 ease-in"
            leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <div
              v-show="open"
              class="flex h-screen items-center justify-center"
            >
              <CloseIcon
                class="absolute right-6 top-6 cursor-pointer text-white hover:opacity-50"
                :size="40"
                @click="(emit('close-modal'), emit('update:open', false))"
              />
              <div
                class="z-[99] inline-block rounded-2xl p-6 py-8 text-left align-bottom shadow-xl transition-all sm:my-auto sm:w-full sm:align-middle"
                :class="{
                  'bg-white': !noBackground,
                  'max-w-5xl': !size,
                  'max-w-3xl': size === 'md',
                  'max-w-xl': size === 'sm'
                }"
              >
                <h1 v-if="title">
                  {{ title }}
                </h1>
                <slot />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'

const props = defineProps<{
  open: boolean
  title?: string
  noBackground?: boolean
  size?: 'sm' | 'md' | 'lg'
}>()

const emit = defineEmits(['close-modal', 'update:open'])

onKeyStroke('Escape', (e) => {
  if (!props.open) return
  emit('close-modal')
  emit('update:open', false)
  e.preventDefault()
})
</script>
